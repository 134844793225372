<template>
  <div class="card-main overview-data-viewer">
    <div class="row">
      <div class="col-md-3">

        <h1>Consignees</h1>

        <table class="table table-bordered-row table-hover overview-data-table">
          <thead>
            <tr>
              <th>
                Consignee
              </th>
              <th style="text-align: right">
                TEU
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, c) in dataConsignees"
              :key="c">
              <td>
                <nuxt-link
                  :to="row.link">
                  {{ row.Consignee }}
                </nuxt-link>
              </td>
              <td style="text-align: right">
                {{ row.CalculatedTEU | toFixed }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="bottom-link">
          <a
            href=""
            class="btn-text"
            @click.prevent="setActiveAggregateView('consignees')">See all consignees</a>
        </div>

      </div>
      <div class="col-md-3">

        <h1>Shippers</h1>

        <table class="table table-bordered-row table-hover overview-data-table">
          <thead>
            <tr>
              <th>
                Shipper
              </th>
              <th style="text-align: right">
                TEU
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, c) in dataShippers"
              :key="c">
              <td>
                <nuxt-link
                  :to="row.link">
                  {{ row.Shipper }}
                </nuxt-link>
              </td>
              <td style="text-align: right">
                {{ row.CalculatedTEU | toFixed }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="bottom-link">
          <a
            href=""
            class="btn-text"
            @click.prevent="setActiveAggregateView('shippers')">See all shippers</a>
        </div>


      </div>
      <div class="col-md-6">
        <h1>Shipment History</h1>

        <analytics-line-chart
          :line-chart="lineChartData"
          :update-visible-data="false"
          class="overview-analytics"/>

        <div class="bottom-link">
          <a
            href=""
            class="btn-text"
            @click.prevent="setActiveAggregateView('analytics')">Analyze search results</a>
        </div>

      </div>
    </div>
    <hr class="section-divider">
    <div class="row">
      <div class="col-md-12">
        <h1>Shipments</h1>

        <search-selected-data-viewer
          :current-page="1"
          :page-limit="5"
          :data-prop="shipments.slice(0,5)"
          :hide-pagination="true"
          :disabled-column-resizer="[0,1]"
          :sortable="false"
          sort-field="ProductDescription"
          active-aggregate-view="shipments"
          sort-order="asc"
          class="with-top-border"/>

        <div class="bottom-link">
          <a
            href=""
            class="btn-text"
            @click.prevent="setActiveAggregateView('shipments')">See all shipments</a>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SearchSelectedDataViewer from './SearchSelectedDataViewer'
import AnalyticsLineChart from '~/components/analytics/AnalyticsLineChartSection/AnalyticsLineChart'

import md5 from 'md5'

export default {
  components: {
    SearchSelectedDataViewer,
    AnalyticsLineChart
  },
  filters: {
    toFixed(a) {
      return a.toFixed(2)
    }
  },
  computed: {
    ...mapGetters('analytics', [
      'chartTitle',
      'lineChartData',
      'lineChartTableData'
    ]),
    ...mapState('search', ['shipments']),
    ...mapState('search/aggregatedConsignees', ['consignees']),
    ...mapState('search/aggregatedShippers', ['shippers']),
    dataConsignees() {
      return this.consignees.slice(0, 5).map(v => {
        v.link = `/company/${md5(v['Consignee'])}/overview`
        return v
      })
    },
    dataShippers() {
      return this.shippers.slice(0, 5).map(v => {
        v.link = `/company/${md5(v['Shipper'])}/overview`
        return v
      })
    }
  },
  methods: {
    ...mapMutations('views', ['setActiveAggregateView'])
  }
}
</script>
