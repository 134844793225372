var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-base",class:{
    'full-screen': _vm.inFullscreen,
    'height-100': (_vm.dataTableTotal && _vm.dataTableTotal < 5),
    'padding-b-0': !_vm.totalShipments,
    'zero-results': !_vm.totalShipments
  }},[_vm._m(0),_vm._v(" "),_c('search-criteria-bar',{attrs:{"fields":_vm.fields,"search-credits":_vm.searchCredits}}),_vm._v(" "),_c('data-viewer-header',{attrs:{"show-full-screen-icon":false},on:{"view-modal":_vm.setCustomViewOpen},scopedSlots:_vm._u([{key:"headerRight",fn:function(ref){return [_c('shipment-alert-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCurrentSearch && _vm.showShipmentAlertButton),expression:"hasCurrentSearch && showShipmentAlertButton"}],attrs:{"user-email":_vm.user.email}}),_vm._v(" "),(!_vm.isAggregateViewAnalytics)?_c('download-results',{attrs:{"disabled":_vm.disableDownload,"result-limit":_vm.maxDownloadLimit,"type":_vm.downloadType}}):_c('analytics-downloader',{attrs:{"disabled":_vm.disableDownload,"chart-type":_vm.chartType}}),_vm._v(" "),_c('bookmark-popover',{attrs:{"is-disabled":_vm.isAggregateViewAnalytics,"has-data":_vm.activeTotal,"disabled":_vm.isAggregateViewAnalytics}}),_vm._v(" "),(_vm.showFullScreenIcon)?_c('el-tooltip',{attrs:{"content":_vm.inFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}},[_c('i',{class:{
            'minimize-window-icon': _vm.inFullscreen,
            'full-window-icon': !_vm.inFullscreen
          },on:{"click":function($event){return _vm.setInFullscreen(!_vm.inFullscreen)}}})]):_vm._e()]}}])},[_c('template',{slot:"headerLeft"},[_c('aggregate-header-tabs')],1)],2),_vm._v(" "),(_vm.isAggregateViewOverview && _vm.hasData)?_c('overview'):_vm._e(),_vm._v(" "),(!_vm.isAggregateViewAnalytics && !_vm.isAggregateViewOverview)?_c('search-selected-data-viewer',{ref:"searchSelectedDataViewer",attrs:{"loading":_vm.loading,"data-prop":_vm.dataViewTable,"date-range-text":_vm.dateRangeText,"limit":_vm.dataTableLimit,"total":_vm.dataTableTotal,"sort-field":_vm.dataTableSortField,"sort-order":_vm.dataTableSortOrder,"page-limit":_vm.dataTablePageLimit,"current-page":_vm.dataTableCurrentPage,"active-aggregate-view":_vm.activeAggregateView,"has-checkboxes":true,"show-custom-view":false,"show-cards":true,"show-cards-icon":false,"show-full-screen-icon":false,"floating-scrollbar-options":_vm.floatingScrollbarOptions},on:{"sort":_vm.dataTableSortShipmentsAndClearCheckboxes,"limit":_vm.dataTableSetPageLimit,"page":_vm.dataTableChangeShipmentsCurrentPage,"rows-selected":_vm.dataTableSetSelectedRow}}):_vm._e(),_vm._v(" "),(_vm.isAggregateViewAnalytics && _vm.hasData)?_c('analytics'):_vm._e(),_vm._v(" "),(!_vm.hasData)?_c('criteria-suggestion',{attrs:{"data":_vm.dataViewTable}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-gen-ai"},[_c('div',{staticClass:"alert alert-dismissible alert-danger",attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","default":"default","body":"Please select at least one company and then click the “Generate Company Profile” button to generate."}},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"}},[_vm._v("×")]),_vm._v(" "),_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"material-icons icon-md icon-critical icons-filled"},[_vm._v("error")])]),_vm._v(" "),_c('div',{staticClass:"alert-content"},[_c('div',{staticClass:"alert-title"},[_vm._v("\n          No company has been selected.\n        ")]),_vm._v(" "),_c('div',{staticClass:"alert-message"},[_vm._v("Please select at least one company and then click the “Generate Company Profile” button to generate.")])])])])}]

export { render, staticRenderFns }