<template>
  <ValidationObserver
    v-slot="{ invalid }">
    <el-popover
      slot="reference"
      :value="popoverOpen"
      placement="bottom-start"
      trigger="manual"
      popper-class="light shipment-alerts-form with-footer">
      <div
        v-click-outside="() => popoverOpen = false">
        <div
          v-if="shipmentAlertStatus === Status.ACTIVE && emailAlertUnavailable"
          class="popover-content">
          <h3>email alerts not available</h3>
          <p>The email alerts feature is currently not available to your account. Contact support to create shipment alerts.
          </p>
          <button
            class="btn btn-text view-shipment-alerts"
            @click="$router.push('/shipment-alerts')" >
            View my shipment alerts
          </button>
        </div>

        <div
          v-else-if="shipmentAlertStatus === Status.ACTIVE && !emailAlertsCredits"
          class="popover-content">
          <h3>no more shipment alerts</h3>
          <p>You have already hit the limit of your account's shipment alerts. Remove existing shipment alerts or contact support to get more shipment alerts.
          </p>
          <button
            class="btn btn-text view-shipment-alerts"
            @click="$router.push('/shipment-alerts')" >
            View my shipment alerts
          </button>
        </div>

        <div
          v-else-if="shipmentAlertStatus == 'active'"
          class="popover-content">
          <h3>subscribe to this search</h3>
          <p>
            Get email alerts when new shipments matching your search clear Customs
          </p>
          <fieldset>
            <h3>Alert Name</h3>
            <ValidationProvider
              :immediate="true"
              name="Alert name"
              rules="required|max:127"
              class="form-field">
              <span
                slot-scope="{ errors }"
                class="input-field">
                <input
                  v-model="alertName"
                  :class="{ error: !!errors.length}"
                  type="text"
                  placeholder="File Name (Optional)"
                  @keyup.enter="createAlert">
                <span class="alert-status error">{{ errors[0] }}</span>
              </span>
            </ValidationProvider>
          </fieldset>
          <fieldset>
            <h3>Email Address</h3>
            <ValidationProvider
              :immediate="true"
              name="Email Address"
              rules="required|email"
              class="form-field">
              <span
                slot-scope="{ errors }"
                class="input-field">
                <input
                  v-model="emailAddress"
                  :class="{ error: !!errors.length}"
                  type="email"
                  name="email"
                  placeholder="Recipient email address"
                  class="full-width"
                  @keyup.enter="createAlert">
                <span class="alert-status error">{{ errors[0] }}</span>
              </span>
            </ValidationProvider>
          </fieldset>
        </div>

        <div
          v-else-if="shipmentAlertStatus == Status.CREATED"
          class="popover-content">
          <h3>shipment alert active</h3>
          <p>You will receive an email at <strong>{{ emailAddress }}</strong> whenever a new shipment matching your search clears customs.
          </p>
          <button
            class="btn btn-text view-shipment-alerts"
            @click="$router.push('/shipment-alerts')" >
            View my shipment alerts
          </button>
        </div>

        <div
          v-if="shipmentAlertStatus == Status.ACTIVE && emailAlertsCredits"
          class="popover-footer flex-container justify-right">
          <button
            class="btn btn-text secondary"
            @click="popoverOpen = false">Cancel</button>
          <button
            :class="{disabled: invalid || $actions.pending('search/createShipmentAlert')}"
            class="btn btn-default btn-primary flex-items"
            @click="createAlert">
            Create Alert
          </button>
        </div>

        <div
          v-else
          class="popover-footer flex-container justify-right">
          <button
            class="btn btn-text secondary"
            @click="popoverOpen = false">Close</button>
        </div>

      </div>
      <button 
        slot="reference"
        :class="{ disabled }"
        class="btn btn-plain btn-sm"
        @click="popoverOpen = !popoverOpen">
        <span class="material-icons">notifications</span>
        Create Shipment Alert 
      </button>

    </el-popover>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { splitAdvancedKeywords } from '~/utils/advancedSearch'
import { Status } from '~/utils/constants/shipmentAlerts'
import validator from 'validator'

export default {
  name: 'ShipmentAlertDialog',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    userEmail: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverOpen: false,
      enableShareResult: true,
      alertName: '',
      emailAddress: '',
      Status
    }
  },
  computed: {
    ...mapState('search', ['shipmentAlertStatus']),
    ...mapState('userCredits', ['emailAlerts']),
    ...mapGetters('search', ['searchKeywords']),
    ...mapGetters('userCredits', ['emailAlertsCredits']),
    defaultAlertName() {
      const firstKeywordString = splitAdvancedKeywords(this.searchKeywords)
        .keyword[0]
      if (!firstKeywordString) return ''
      return `${firstKeywordString.trim()} Shipment Alert`
    },
    emailAlertUnavailable() {
      return this.emailAlerts.limit === 0
    }
  },
  watch: {
    searchKeywords() {
      this.alertName = this.defaultAlertName
      this.emailAddress = this.userEmail
    }
  },
  mounted() {
    this.emailAddress = this.userEmail
    this.alertName = this.defaultAlertName
  },
  methods: {
    ...mapActions('search', ['createShipmentAlert']),
    createAlert() {
      if (!this.validateInputs()) return

      this.createShipmentAlert({
        name: this.alertName,
        emailAddress: this.emailAddress
      })
    },
    validateInputs() {
      const maxNameLength = 128
      this.emailAddress = this.emailAddress.trim()
      this.alertName = this.alertName.trim()

      const isEmailValid = validator.isEmail(this.emailAddress)
      const isNameValid =
        !!this.alertName && this.alertName.length < maxNameLength
      return isNameValid && isEmailValid
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/components/data-viewer/shipment-alerts-form.scss"/>
