<template>
  <div class="data-table-controls">
    <aggregate-header-tabs />
    <div class="data-table-controls-actions">
      <button
        v-if="isCompanyProfileGenAiEnabled"
        :disabled="isGenerateButtonDisabled"
        type="button"
        class="btn btn-plain btn-sm gen-ai-btn"
        @click="handleGenerateClick">
        <span class="gen-ai-icon"/>
        <span class="btn-label">
          Generate Company Profile
          <i>(powered by ImportGenius AI)</i>
        </span>
        <span class="gen-ai-badge">New</span>
      </button>
      <download-results :result-limit="10" />
      <shipment-alert-dialog />
      <bookmark-popover />
      <button
        type="button"
        class="btn btn-plain btn-sm"
        @click="$emit('full-screen', !showFullScreen)">
        <!--
        Note to Dev:
        1. Replace 'fullscreen' in <span class="material-icons">fullscreen</span> with 'fullscreen_exit' when entering full screen, and switch it back when exiting.
        2. Replace 'Go Fullscreen' with 'Exit Fullscreen' when entering full screen, and switch it back when exiting.
        -->
        <span class="material-icons">fullscreen</span>
        Go Fullscreen
      </button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      custom-class="ai-gen-download-report dialog-with-footer"
      title="Download AI-generated report"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.9)">
      <div class="company-profile-ai-gen-header">
        AI-Generated Shipments Summary
        <el-popover
          placement="bottom"
          width="300"
          trigger="hover"
          popper-class="ai-gen-popover"
          content="AI-generated content may contain inaccurate information. Please verify the data with external sources. External links are provided for reference and are not affiliated with ImportGenius.">
          <el-button slot="reference"> <span class="gen-ai-icon" /></el-button>
        </el-popover>
      </div>
      <div
        v-loading="loading"
        class="company-profile-ai-gen-content">
        <div
          class="company-profile-ai-gen-content-main"
          style="display: none;">
          <div v-if="!loading">
            <div
              class="mb-0"
              v-html="profileSummary"/>
            <gen-ai-ratings class="my-50"/>
          </div>
        </div>
        <div class="data-table">
          <div class="table-wrapper">
            <table
              ref="table"
              :class="tableClass"
              class="table table-bordered-row table-hover table-bordered">
              <thead>
                <tr>
                  <th><h3>Company Name</h3></th>
                  <th><h3>Overview</h3></th>
                  <th><h3>Shipments Trends and Potential Synergies</h3></th>
                  <th><h3>Product and Services</h3></th>
                  <th><h3>Competitors</h3></th>
                  <th><h3>Finance</h3></th>
                </tr>
              </thead>
              <tbody
                v-if="!loading"
                ref="tableBody">
                <template>
                  <tr
                    v-for="(profile, companyName, index) in companyProfiles"
                    v-if="index < 5"
                    :key="companyName">
                    <td>{{ companyName }}</td>
                    <td>{{ profile.Overview ? truncateText(profile.Overview, 150) : 'Not available' }}</td>
                    <td>{{ profile.middle_points ? truncateText(profile.middle_points, 150) : 'Not available' }}</td>
                    <td>{{ profile.Product ? truncateText(profile.Product, 150) : 'Not available' }}</td>
                    <td>{{ profile.Competitors ? truncateText(profile.Competitors, 150) : 'Not available' }}</td>
                    <td>{{ profile.Finance ? truncateText(profile.Finance, 150) : 'Not available' }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="ai-gen-download-report-footer">
        <!-- Loading state -->
        <el-button
          :class="{'btn-primary': !loadingButton, 'is-loading': loadingButton}"
          :disabled="loading"
          class="btn btn-block"
          type="primary"
          @click="handleDownload">
          <span v-if="!loadingButton">Download AI-generated report for all companies</span>
          <span v-else>
            <div class="el-loading-spinner">
              <svg
                viewBox="25 25 50 50"
                class="circular">
                <circle
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  class="path"/>
              </svg>
            </div>
          </span>
        </el-button>
        <p>The report in XLS format, includes shipment trends, finances, and key contact information for each company. Please allow a few moments for the download to complete.</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Showdown from 'showdown'
import { mapState } from 'vuex'
import ViewsMenu from '~/components/data-viewers/plugins/ViewsMenu/ViewsMenu'
import DownloadResults from './DownloadResults.vue'
import ShipmentAlertDialog from './ShipmentAlertDialog.vue'
import BookmarkPopover from './BookmarkPopover.vue'
import AggregateHeaderTabs from './AggregateHeaderTabs.vue'
import GenAiRatings from '~/components/common/GenAiRatings'
import { companyProfileGenAiMixin } from '~/mixins/companyProfileGenAiMixin'

export default {
  // @ts-ignore.
  // Note for Dev: This is for material icons. Move this if necessary for proper implemetation.
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
        }
      ]
    }
  },
  name: 'DataViewerHeader',
  components: {
    ViewsMenu,
    DownloadResults,
    ShipmentAlertDialog,
    BookmarkPopover,
    AggregateHeaderTabs,
    GenAiRatings
  },
  mixins: [companyProfileGenAiMixin],
  props: {
    showCardsIcon: {
      type: Boolean,
      default: false
    },
    showFullScreen: {
      type: Boolean,
      default: false
    },
    showFullScreenIcon: {
      type: Boolean,
      default: true
    },
    showCustomView: {
      type: Boolean,
      default: false
    },
    viewType: {
      type: String,
      default: 'table',
      validator(value) {
        switch (value) {
          case 'table':
          case 'card':
            return true
          default:
            return false
        }
      }
    }
  },

  data() {
    return {
      dialogVisible: false,
      alertTimeout: null,
      loading: false,
      companyProfiles: {},
      profileSummary: '',
      loadingButton: false,
      tableClass: '',
      isGenerateButtonDisabled: false
    }
  },
  computed: {
    ...mapState('tours', ['FirstLoginTour']),
    ...mapState({
      selectedShipmentIndexes: state =>
        state.downloads.selectedRows.shipments || [],
      selectedShipperIndexes: state =>
        state.downloads.selectedRows.shippers || [],
      selectedConsigneeIndexes: state =>
        state.downloads.selectedRows.consignees || []
    }),
    hasSelectedRows() {
      return (
        (this.selectedShipmentIndexes &&
          this.selectedShipmentIndexes.length > 0) ||
        (this.selectedShipperIndexes &&
          this.selectedShipperIndexes.length > 0) ||
        (this.selectedConsigneeIndexes &&
          this.selectedConsigneeIndexes.length > 0)
      )
    }
  },
  mounted() {
    this.updateGenerateButtonState()

    this.buttonCheckInterval = setInterval(() => {
      this.updateGenerateButtonState()
    }, 500)
  },
  beforeDestroy() {
    if (this.buttonCheckInterval) {
      clearInterval(this.buttonCheckInterval)
    }
  },
  methods: {
    updateGenerateButtonState() {
      const companyCount = this.getSelectedCompanyNames().length
      this.isGenerateButtonDisabled = companyCount > 5
    },
    openCustomView(open) {
      this.$emit('view-modal', open)
    },
    async handleGenerateClick() {
      if (this.hasSelectedRows) {
        this.hideAlert()
        this.dialogVisible = true
        this.loading = true
        this.companyProfiles = {}
        this.profileSummary = ''

        try {
          const response = await this.fetchCompanyProfiles()

          if (response.success) {
            this.companyProfiles = response.data.profiles || {}
            const converter = new Showdown.Converter()
            Object.keys(this.companyProfiles).forEach(companyName => {
              const profile = this.companyProfiles[companyName]
              if (profile.Overview) {
                profile.Overview = converter.makeHtml(profile.Overview)
              }
              if (profile.middle_points) {
                profile.middle_points = converter.makeHtml(
                  profile.middle_points
                )
              }
              if (profile.Product) {
                profile.Product = converter.makeHtml(profile.Product)
              }
              if (profile.Competitors) {
                profile.Competitors = converter.makeHtml(profile.Competitors)
              }
              if (profile.Finance) {
                profile.Finance = converter.makeHtml(profile.Finance)
              }
            })
            this.profileSummary = response.data.summary
              ? converter.makeHtml(response.data.summary)
              : ''
          } else {
            console.error('Failed to fetch company profiles')
            this.profileSummary =
              'Failed to load company profiles. Please try again later.'
          }
        } catch (error) {
          console.error('Error fetching company profiles:', error)
          this.profileSummary =
            'An error occurred while loading company profiles. Please try again later.'
        } finally {
          this.loading = false
        }
      } else {
        this.showAlert()

        clearTimeout(this.alertTimeout)
        this.alertTimeout = setTimeout(() => {
          this.hideAlert()
        }, 3000)
      }
    },
    checkActiveButtonHasText(textToCheck) {
      if (!textToCheck || typeof textToCheck !== 'string') {
        return false
      }

      const activeButtons = document.querySelectorAll(
        '.data-table-controls button.active'
      )

      for (const button of activeButtons) {
        const buttonText = button.textContent.trim()

        if (buttonText.includes(textToCheck)) {
          return true
        }
      }

      return false
    },
    getSelectedCompanyNames() {
      const companyNames = []
      const store = this.$store.state
      if (
        store.downloads.selectedRows.shipments &&
        store.downloads.selectedRows.shipments.length > 0 &&
        this.checkActiveButtonHasText('Shipments')
      ) {
        const selectedIndices = store.downloads.selectedRows.shipments
        const shipments = store.search.shipments

        selectedIndices.forEach(indexStr => {
          const index = parseInt(indexStr, 10)
          const shipment = shipments.find(item => item.index === index)
          if (shipment && shipment.Consignee) {
            companyNames.push(shipment.Consignee)
          }
        })
      }

      if (
        store.downloads.selectedRows.consignees &&
        store.downloads.selectedRows.consignees.length > 0 &&
        this.checkActiveButtonHasText('Consignees')
      ) {
        const selectedIndices = store.downloads.selectedRows.consignees
        const consignees = store.search.aggregatedConsignees.consignees

        selectedIndices.forEach(indexStr => {
          const index = parseInt(indexStr, 10)
          const consignee = consignees.find(item => item.index === index)
          if (consignee && consignee.Consignee) {
            companyNames.push(consignee.Consignee)
          }
        })
      }

      if (
        store.downloads.selectedRows.shippers &&
        store.downloads.selectedRows.shippers.length > 0 &&
        this.checkActiveButtonHasText('Shippers')
      ) {
        const selectedIndices = store.downloads.selectedRows.shippers
        const shippers = store.search.aggregatedShippers.shippers

        // For shippers, we don't have index property, so we need to use array index
        // Note: The indices start from 1 in the selectedRows but arrays are 0-indexed
        selectedIndices.forEach(indexStr => {
          const index = parseInt(indexStr, 10)
          // Since index starts from 1, we subtract 1 to get the array index
          const arrayIndex = index - 1

          if (arrayIndex >= 0 && arrayIndex < shippers.length) {
            const shipper = shippers[arrayIndex]
            if (shipper && shipper.Shipper) {
              companyNames.push(shipper.Shipper)
            }
          }
        })
      }

      // Remove duplicates if needed
      return [...new Set(companyNames)]
    },
    async fetchCompanyProfiles() {
      const { id: clientId } = this.$auth.user
      const apiUrl = `${COMPANY_PROFILE_LAMBDA_URL}/clients/${clientId}/companies/profiles/ai/precache`
      const companyNames = this.getSelectedCompanyNames()

      try {
        const response = await this.$axios.post(apiUrl, { companyNames })
        return response.data
      } catch (error) {
        console.error('API request failed:', error)
        throw error
      }
    },
    truncateText(text, maxLength) {
      if (!text) return ''

      const plainText = text.replace(/<[^>]*>/g, '')
      return plainText.length > maxLength
        ? `${plainText.substring(0, maxLength)}...`
        : plainText
    },
    showAlert() {
      const alert = document.querySelector('.alert-gen-ai')

      if (alert) {
        alert.style.display = 'block'

        clearTimeout(this.alertTimeout)
        this.alertTimeout = setTimeout(() => {
          this.hideAlert()
        }, 3000)
      }
    },
    hideAlert() {
      const alert = document.querySelector('.alert-gen-ai')
      if (alert) {
        alert.style.display = 'none'
      }
    },
    async updateRating(value) {
      console.log('Rating:', value)
      // In a real implementation, make a real API call to rating endpoint
      // const { data } = await this.$axios.$post(`/rating`)
      this.rating = value
    },
    handleClose() {
      this.dialogVisible = false
    },
    async handleDownload() {
      this.loadingButton = true

      try {
        const { id: clientId } = this.$auth.user
        const companyNames = this.getSelectedCompanyNames()

        const response = await this.$axios.$post(
          `${COMPANY_PROFILE_LAMBDA_URL}/clients/${clientId}/companies/profiles/ai/download`,
          { companyNames },
          {
            responseType: 'blob',
            headers: {
              Accept:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'Content-Type': 'application/json'
            }
          }
        )

        if (!response) {
          throw new Error('Downloaded file is empty.')
        }

        const url = window.URL.createObjectURL(response)
        const a = document.createElement('a')
        a.href = url
        a.download = 'company-profiles.xlsx'
        document.body.appendChild(a)
        a.click()

        // Cleanup
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        this.$message({
          message: `Download completed successfully!`,
          type: 'success'
        })
      } catch (error) {
        console.error('Error downloading the file:', error)
        this.$message({
          message: `Failed to download the report`,
          type: 'error'
        })
      } finally {
        this.loadingButton = false
      }
    }
  }
}
</script>
<style src="~/assets/scss/components/data-viewer/data-table-controls.scss" lang="scss"/>

<style>
.hover-disabled {
  &:hover {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
</style>
